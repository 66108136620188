




































































































































































































































































































































































































import { isEmptyOrWhiteSpace } from "@/utils/Common";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component
export default class FeeRemindView extends Vue {
  @Prop({ type: Object, required: true })
  remindDetail!: any;

  currentTab = "";
  feeObj: any = {
    list1day: [],
    list1week: [],
    list15day: [],
    list1month: [],
    list2month: [],
    list3month: [],
    list1daysum: 0,
    list1weeksum: 0,
    list15daysum: 0,
    list1monthsum: 0,
    list2monthsum: 0,
    list3monthsum: 0
  };

  currentFeePage1Day = 1;
  feePageSize1Day = 10;

  currentFeePage1Week = 1;
  feePageSize1Week = 10;

  currentFeePage15Day = 1;
  feePageSize15Day = 10;

  currentFeePage1Month = 1;
  feePageSize1Month = 10;
  
  currentFeePage2Month = 1;
  feePageSize2Month = 10;

  currentFeePage3Month = 1;
  feePageSize3Month = 10;
  
  resetData() {
    this.feeObj = {
      list1day: [],
      list1week: [],
      list15day: [],
      list1month: [],
      list2month: [],
      list3month: [],
      list1daysum: 0,
      list1weeksum: 0,
      list15daysum: 0,
      list1monthsum: 0,
      list2monthsum: 0,
      list3monthsum: 0
    };
    this.currentFeePage1Day = 1;
    this.feePageSize1Day = 10;

    this.currentFeePage1Week = 1;
    this.feePageSize1Week = 10;

    this.currentFeePage15Day = 1;
    this.feePageSize15Day = 10;

    this.currentFeePage1Month = 1;
    this.feePageSize1Month = 10;

    this.currentFeePage2Month = 1;
    this.feePageSize2Month = 10;

    this.currentFeePage3Month = 1;
    this.feePageSize3Month = 10;
  }

  @Watch("remindDetail", { immediate: true, deep: true })
  onDataChange() {
    this.resetData();
    this.feeObj = JSON.parse(this.remindDetail.content) || {};
    let tab = "";
    this.feeObj.list1daysum = 0;
    if (this.feeObj.list1day) {
      this.feeObj.list1day.forEach((f: any) => {
        this.feeObj.list1daysum += parseFloat(f.a);
      });
    } else {
      this.feeObj.list1day = [];
    }

    this.feeObj.list1weeksum = 0;
    if (this.feeObj.list1week) {
      this.feeObj.list1week.forEach((f: any) => {
        this.feeObj.list1weeksum += parseFloat(f.a);
      });
    } else {
      this.feeObj.list1week = [];
    }

    this.feeObj.list15daysum = 0;
    if (this.feeObj.list15day) {
      this.feeObj.list15day.forEach((f: any) => {
        this.feeObj.list15daysum += parseFloat(f.a);
      });
    } else {
      this.feeObj.list15day = [];
    }

    this.feeObj.list1monthsum = 0;
    if (this.feeObj.list1month) {
      this.feeObj.list1month.forEach((f: any) => {
        this.feeObj.list1monthsum += parseFloat(f.a);
      });
    } else {
      this.feeObj.list1month = [];
    }

    this.feeObj.list2monthsum = 0;
    if (this.feeObj.list2month) {
      this.feeObj.list2month.forEach((f: any) => {
        this.feeObj.list2monthsum += parseFloat(f.a);
      });
    } else {
      this.feeObj.list2month = [];
    }

    this.feeObj.list3monthsum = 0;
    if (this.feeObj.list3month) {
      this.feeObj.list3month.forEach((f: any) => {
        this.feeObj.list3monthsum += parseFloat(f.a);
      });
    } else {
      this.feeObj.list3month = [];
    }

    if (this.feeObj.list1daysum > 0) {
      tab = "list1daysum";
    } else if (this.feeObj.list1weeksum > 0) {
      tab = "list1weeksum";
    } else if (this.feeObj.list15daysum > 0) {
      tab = "list15daysum";
    } else if (this.feeObj.list1monthsum > 0) {
      tab = "list1monthsum";
    } else if (this.feeObj.list2monthsum > 0) {
      tab = "list2monthsum";
    } else if (this.feeObj.list3monthsum > 0) {
      tab = "list3monthsum";
    }
    if (!isEmptyOrWhiteSpace(tab)) {
      this.currentTab = tab;
    }
  }

  getRowNumber1Day(rowIndex: number) {
    return (
      (this.currentFeePage1Day - 1) * this.feePageSize1Day + (rowIndex + 1)
    );
  }

  getRowNumber1Week(rowIndex: number) {
    return (
      (this.currentFeePage1Week - 1) * this.feePageSize1Week + (rowIndex + 1)
    );
  }

  getRowNumber15Day(rowIndex: number) {
    return (
      (this.currentFeePage15Day - 1) * this.feePageSize15Day + (rowIndex + 1)
    );
  }

  getRowNumber1Month(rowIndex: number) {
    return (
      (this.currentFeePage1Month - 1) * this.feePageSize1Month + (rowIndex + 1)
    );
  }

  getRowNumber2Month(rowIndex: number) {
    return (
      (this.currentFeePage2Month - 1) * this.feePageSize2Month + (rowIndex + 1)
    );
  }

  getRowNumber3Month(rowIndex: number) {
    return (
      (this.currentFeePage3Month - 1) * this.feePageSize3Month + (rowIndex + 1)
    );
  }

  handleCurrentFeeChange1Day(page: number) {
    this.currentFeePage1Day = page;
  }

  handleCurrentFeeChange1Week(page: number) {
    this.currentFeePage1Week = page;
  }

  handleCurrentFeeChange15Day(page: number) {
    this.currentFeePage15Day = page;
  }

  handleCurrentFeeChange1Month(page: number) {
    this.currentFeePage1Month = page;
  }

  handleCurrentFeeChange2Month(page: number) {
    this.currentFeePage2Month = page;
  }

  handleCurrentFeeChange3Month(page: number) {
    this.currentFeePage3Month = page;
  }
}
