




















































































































































































import { API_REMIND_LIST, API_REMIND_MY_LIST } from "@/configs/Apis";
import formatters from "@/utils/ColumnFormater";
import {
  isEmpty,
  isEmptyOrWhiteSpace,
  showConfirm,
  showError,
  showSuccess,
  showWarning,
} from "@/utils/Common";
import FeeRemindView from "@/views/message/subviews/FeeRemindView.vue";
import moment, { Moment } from "moment";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    FeeRemindView,
  },
})
export default class MessageIndex extends Vue {
  nodeQueryData = "";
  queryData: any = { remindDateOption: "all" };

  remindDetail: any = {};
  showRight = false;

  hasSelectedItems = false;

  queryStr = "";
  loadData = false;

  showSearch = false;

  get treeData() {
    const module = this.$store.state.message;
    if (!module) {
      return null;
    }
    return module.messageTree;
  }

  onTreeNodeClick(index: string) {
    if (isEmptyOrWhiteSpace(index)) {
      return;
    }
    const parts = index.split("-");
    let nodeData: any = null;
    if (parts.length === 1) {
      nodeData = this.treeData[parts[0]];
    } else {
      nodeData = this.treeData[parts[0]].children[parts[1]];
    }
    if (!nodeData) {
      return;
    }
    this.nodeQueryData = JSON.stringify(nodeData.remindMessageFilter || {});
    // this.onQueryChanged();
  }

  get searchBtnIcon(): string {
    return this.showSearch ? "el-icon-arrow-up" : "el-icon-arrow-down";
  }

  get dataApi() {
    return API_REMIND_LIST;
  }

  get colFormatters() {
    return formatters;
  }

  createTableColumns() {
    const columns: Array<TableColumnOption> = [
      { prop: "remindTime", display: "时间", width: 160 },
      { prop: "toUserId", display: "接收人", width: 200 },
      { prop: "messageType", display: "类型", width: 130 },
      { prop: "title", display: "标题", width: 240, clickable: true },
      { prop: "content", display: "详情" },
    ];

    this.applyColumnFormatters(columns);
    this.$store.dispatch("message/setColumns", columns);
  }

  applyColumnFormatters(columns: Array<TableColumnOption>) {
    const fmers = {
      messageType: this.colFormatters.formatMessageType.bind(this),
      toUserId: (data: any, column: any, value: any) => {
        return data.toUser.name;
      },
      title: (data: any, column: any, value: any) => {
        return data.readFlag ? value : `<font color="#F56C6C">[未读] ${value}</font>`;
      },
      content: (data: any, column: any, value: any) => {
        return data.messageType === "WEIXIN_PUSH" &&
        data.content.indexOf("list3") > 0
          ? "<font color=\"#5867DD\">点击查看详情</font>"
          : value;
      },
    };
    if (!columns || !columns.length) {
      return;
    }
    columns.forEach((option) => {
      if (!Object.prototype.hasOwnProperty.call(fmers, option.prop)) {
        return;
      }
      option.formatter = (fmers as any)[option.prop];
    });
  }

  reloadTreeAndData() {
    this.reloadMessageTree();
    this.searchData();
  }

  onModuleRegistered() {
    this.$nextTick()
      .then(() => this.createTableColumns())
      .then(() => {
        this.loadMessageTree();
        this.onSearchConditionChange();
      });
  }

  loadMessageTree() {
    return this.$store.dispatch("message/getMessageTree").then(() => {
      this.queryData = this.treeData[0].remindMessageFilter || {};
      this.nodeQueryData = JSON.stringify(this.queryData);
      this.queryStr = this.nodeQueryData;
    });
  }

  reloadMessageTree() {
    return this.$store.dispatch("message/getMessageTree");
  }

  onTextKeyup() {
    ((this.$refs.searchBtn as Vue).$el as HTMLInputElement).focus();
  }

  onRemindDateChange(arrs: Array<string>) {
    if (arrs && arrs.length) {
      this.queryData.startDate = arrs[0];
      this.queryData.endDate = arrs[1];
    } else {
      (this.queryData.startDate as any) = undefined;
      (this.queryData.endDate as any) = undefined;
    }
    this.onSearchConditionChange();
  }

  @Watch("queryData.readFlag")
  @Watch("queryData.title")
  onSearchConditionChange() {
    const params: { [key: string]: any } = {};
    if (!isEmptyOrWhiteSpace(this.nodeQueryData)) {
      const nodeFilter = JSON.parse(this.nodeQueryData);
      Object.keys(nodeFilter).forEach(
        (name) => (params[name] = nodeFilter[name])
      );
    }
    if (!isEmptyOrWhiteSpace(this.queryData.title)) {
      params.title = this.queryData.title;
    }
    if (!isEmptyOrWhiteSpace(this.queryData.readFlag)) {
      params.readFlag = this.queryData.readFlag;
    }
    if (!isEmptyOrWhiteSpace(this.queryData.startDate)) {
      params.startDate = this.queryData.startDate;
    }
    if (!isEmptyOrWhiteSpace(this.queryData.endDate)) {
      params.endDate = this.queryData.endDate;
    }
    const paramsStr = JSON.stringify(params);
    if (paramsStr === "{}" && this.queryStr.trim() === "") {
      this.queryStr = "{}";
    } else if (paramsStr !== this.queryStr.trim()) {
      this.queryStr = JSON.stringify(params);
    }
    if (this.queryStr === "{}") {
      this.queryStr = JSON.stringify({
        toUserId: this.$store.getters["user/user"].id,
      });
    }
  }

  @Watch("queryData.remindDateOption")
  onRemindDateOptionChange() {
    let now: Moment | null = moment();
    let startDate: Moment | null = moment();
    switch (this.queryData.remindDateOption) {
      case "oneweek":
        startDate = startDate.add(-7, "days");
        break;
      case "onemonth":
        startDate = startDate.add(-1, "months");
        break;
      case "threemonth":
        startDate = startDate.add(-3, "months");
        break;
      default:
        now = null;
        startDate = null;
        break;
    }
    if (!isEmpty(now)) {
      this.queryData.endDate = now!.format("YYYY-MM-DD");
    } else {
      (this.queryData.endDate as any) = undefined;
    }
    if (!isEmpty(startDate)) {
      this.queryData.startDate = startDate!.format("YYYY-MM-DD");
    } else {
      (this.queryData.startDate as any) = undefined;
    }
    if (this.queryData.remindDateOption !== "custom") {
      this.onSearchConditionChange();
    }
    if (
      this.queryData.remindDateOption !== "custom" &&
      Reflect.has(this.queryData, "remindDate")
    ) {
      this.queryData.remindDate = null;
    }
  }

  @Watch("nodeQueryData")
  onNodeQueryDataChange() {
    this.queryData = { remindDateOption: "all" };
    this.onSearchConditionChange();
  }

  searchData() {
    if (this.queryStr.endsWith(" ")) {
      this.queryStr = this.queryStr.trim();
    } else {
      this.queryStr += " ";
    }
  }

  onSelectionChanged(selectedRows: any[]) {
    this.hasSelectedItems = selectedRows.length > 0;
  }

  hideFloater() {
    this.showRight = false;
  }

  onRowClick(row: any, column: any, event: MouseEvent) {
    this.showRight = true;
    if (event.stopPropagation) {
      event.stopPropagation();
    } else {
      event.cancelBubble = true;
    }
    this.remindDetail = row;
    const userId = this.$store.getters["user/user"].id;
    if (userId === row.toUserId && !row.readFlag) {
      this.$store
        .dispatch("message/readMessage", { selectedIds: [row.id] })
        .then(() => {
          row.readFlag = true;
        })
        .catch(() => "");
    }
  }

  onTableDataLoaded() {
    //
  }

  openSearchPanel() {
    this.showSearch = !this.showSearch;
  }

  batchUnReadData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.messagegrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    showConfirm(`是否确认将 ${msg} 设为已读？`).then(() => {
      this.$store
        .dispatch("message/readMessage", params)
        .then(() => {
          showSuccess("操作成功");
          this.reloadTreeAndData();
        })
        .catch(({ message }) => showError(message));
    });
  }

  batchDeleteData(cmd: string) {
    let params: any = {};
    let msg = "当前列表";
    if (cmd === "selected") {
      const selectedRows = (this.$refs.messagegrid as any).getSelectedRows();
      if (!selectedRows || selectedRows.length === 0) {
        showWarning("请至少选择一条数据");
        return;
      }
      params.selectedIds = selectedRows;
      msg = `${selectedRows.length} 条数据`;
    } else {
      if (!isEmptyOrWhiteSpace(this.queryStr)) {
        params = JSON.parse(this.queryStr);
      }
    }
    showConfirm(`是否确认删除 ${msg}？`).then(() => {
      this.$store
        .dispatch("message/deleteMessage", params)
        .then(() => {
          showSuccess("删除成功");
          this.reloadTreeAndData();
        })
        .catch(({ message }) => showError(message));
    });
  }
}
